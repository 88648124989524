<template>
  <div>
    <nav-bar v-if="showBackBtn" @onBack="handleBack" />
    <div v-if="isClose">
      <div v-if="validateParams" class="skuTask">
        <h3>{{ needObscure ? OBSCURE_NAME : skuTaskName }}</h3>
        <div>{{ effectiveTime }}</div>
        <div class="skuTaskUpload">
          <div class="skuTaskUploadFile">
            <div class="sku-info">
              <div class="sku-info-row">
                <span>城市：</span><span class="row-text">{{ cityName }}</span>
                <van-button v-bind="btnConfig" @click="$utils.copyText(cityName)">
                  复制</van-button
                >
              </div>
              <span class="districtHint" v-if="districtInfo">{{ `${ '区域要求：'+ districtInfo}` }}</span>
              <div class="sku-info-row">
                <span>商品：</span><span class="row-text">{{ skuName }}</span>
                <van-button v-bind="btnConfig" @click="$utils.copyText(skuName)"
                  >复制</van-button
                >
              </div>
              <div class="sku-info-row">
                <span>样图：（仅供参考，以商品名称为准）</span>
                <van-image
                  v-if="!!headImage.url"
                  class="el-img"
                  fit="contain"
                  :src="headImage.url"
                  @click="previewImage(headImage.url)"
                >
                  <template #loading>
                    <van-loading type="spinner" size="20" />
                  </template>
                </van-image>
                <div v-else class="img-empty">
                  <van-button
                    v-if="!!skuName"
                    v-bind="btnConfig"
                    :loading="headImage.loading"
                    @click="getHeadImage"
                    >刷新</van-button
                  >
                </div>
              </div>
            </div>
            <div class="line gap-top"></div>
            <div class="gap-top">
              <image-collection
                v-if="uploadOptions"
                ref="skuImg"
                :uploadOptions="uploadOptions"
                :accept="accept"
                :task-id="urlParams.userTaskId"
                :job-id="urlParams.jobId"
                :check-repeat="true"
                btn-size="small"
                uploadText="点击添加截图"
                @file-change="onFileChange"
              />
              <div v-else>{{ uploadTips }}</div>
            </div>
          </div>
        </div>
        <div class="submit">
          <van-button
            class="skuTaskSubmit"
            :loading="loading"
            loading-text="提交中..."
            @click="handleSubmit"
          >
            点我提交!
          </van-button>
          <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
        </div>
      </div>
      <paramsError v-else />
    </div>
    <van-empty v-else description />
  </div>
</template>

<script>
import imageCollection from "../ImageCollection/Index";
import NavBar from "../components/nav-bar.vue";
import paramsError from '../components/paramsError.vue'
import {
  getTaskDetails,
  proxyHost,
  userTaskHeadImage,
} from "@/apis";
import { formatDate } from "@/tools/date";
import { checkUploadImages } from "../shared";
import { Toast, Dialog, ImagePreview } from "vant";
import submitFileMixins from '../mixins/submit-file'
import backListMixins from "../mixins/back-list";

const OBSCURE_KEYWORD = "脱敏";

export default {
  name: "SkuTask",
  components: {
    imageCollection,
    NavBar,
    paramsError,
  },
  mixins: [backListMixins, submitFileMixins],
  data() {
    return {
      skuTaskName: "",
      effectiveTime: "",
      loading: false,
      companyId: "",
      companyName: "",
      isClose: true,
      accept: "image/*",
      taskStartTime: 0,
      taskEndTime: 0,
      note: "商品列表长截图（拍照/其他页面无效）",
      cityName: "",
      title: "",
      OBSCURE_NAME: "录屏事件名称",
      needObscure: false,
      recordAppFlag: 1,
      uploadOptions: null,
      uploadTips: "",
      taskType: "",
      skuName: "",
      districtInfo: '',
      headImage: {
        url: "",
        loading: false,
      },
      btnConfig: {
        plain: true,
        hairline: true,
        color: "#1989fa",
        size: "mini",
        type: "primary",
      },
    };
  },

  destroyed() {
    document.title = "";
  },

  methods: {
    // 获取任务详情
    async getJobDetails() {
      try {
        const { userTaskId, status } = this.urlParams;
        const res = await getTaskDetails({ userTaskId, status: status ? +status : undefined });
        if (res) {
          const data = res.data || {};
          if (res.code !== 0)
            return Toast.fail(res.msg || "获取任务详情接口返回错误");
          const time = formatDate(
            new Date(data.jobEndTime),
            "yyyy-MM-dd HH:mm:ss"
          );
          if (data.note) {
            this.note = data.note;
          }
          this.needObscure = !!data.isSensitive;
          this.cityName = data.cityName || "";
          this.skuTaskName = data.name || "";
          if (data.name) {
            if (data?.name?.indexOf(OBSCURE_KEYWORD) > -1 || this.needObscure) {
              document.title = this.OBSCURE_NAME;
            } else {
              document.title = data.name;
            }
          }
          if (data.recordAppFlag === 2) {
            this.recordAppFlag = 2;
          }
          this.effectiveTime = `有效时间至:${time}`;
          this.companyId = data.companyId;
          this.companyName = data.companyName || "";
          this.taskType = data.taskType;
          this.skuName = data.skuName;
          this.headImage.url = data.headImage;
          this.districtInfo = data.districtList;
          if (data.detail) {
            const details = JSON.parse(data.detail);
            this.companyId = details.companyId;
          }
        }
      } catch (err) {
        Toast.fail(err.message || "获取任务详情接口请求错误");
      }
    },
    onFileChange() {
      this.taskStartTime = Date.now();
    },
    async handleSubmit() {
      if (this.loading) return;
      this.loading = true;
      try {
        const imgs = this.$refs.skuImg.getImagesList();
        const submitInfo = await checkUploadImages(imgs);
        if (submitInfo) {
          const verifyValid = await this.$refs.skuImg.checkFileValid();
          if (!verifyValid.valid) {
            Toast.fail(
              verifyValid.messageType
                ? verifyValid.message
                : `${verifyValid.message}，请重新上传后提交`
            );
            return;
          }

          const options = {
            message: this.needObscure
              ? "确认提交？"
              : `正在提交${this.skuTaskName}，请再次确认任务城市！`,
            confirmButtonText: "确认提交",
            cancelButtonText: "我再想想",
          };
          await Dialog.confirm(options);
          await this.fetchTaskNew(submitInfo, imgs, 'skuSearch');
        }
      } catch (err) {
        Toast.fail(err.message || "提交异常！");
      } finally {
        this.loading = false
      }
    },
    /**
     * @method 头图预览
     */
    previewImage(imgUrl) {
      ImagePreview([imgUrl]);
    },
    /**
     * @method 获取头图
     */
    async getHeadImage() {
      try {
        this.headImage.loading = true;
        const res = await userTaskHeadImage({
          userTaskId: this.urlParams.userTaskId,
        });

        if (res?.code === 0) {
          this.headImage.url = res?.data?.headImage;
        } else {
          Toast.fail(res.msg || "获取头图失败！");
        }
      } catch (error) {
        Toast.fail(error.msg || "获取头图异常！");
      } finally {
        this.headImage.loading = false;
      }
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
  },
};
</script>

<style lang="less" scoped>
.skuTask {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .skuTaskUpload {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    .skuTaskUploadFile {
      .upload-top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .toolTip {
          font-size: 13px;
        }
        .auto-recording-button {
          color: #fff;
          background-color: #007aff;
          border-radius: 8px;
        }
      }
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .skuTaskSubmit {
      width: 100%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
.sku-info-row {
  padding: 5px 0;
  & > span {
    display: inline-block;
    vertical-align: top;
    line-height: 24px;
  }
}
.row-text {
  padding-right: 5px;
}
.line {
  width: 100%;
  height: 1px;
  background: #999;
}
.el-img {
  display: block;
  height: 150px;
  margin: 5px auto 0;
  max-width: 100%;
}
.gap-top {
  margin-top: 15px;
}
.img-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: #fff;
}

.districtHint{
   color: red;
   font-size: 15px;
}
</style>